import { useSecureSessionPost } from '~~/src/composables/dataFetching/genericFetchers';
import type { Result } from '~~/src/server/api/[site]/user/login.post';
import { ErrorIdent } from '~/@types/errorIdents';
import { useSessionStore } from '~/stores/useSessionStore';
import { handleLoadingError } from '~/utils/handleLoadingError';
import useRecaptcha from '@/components/formTools/recaptcha/useRecaptcha';

export enum Views {
  LOGIN = 'login',
  FORGOT = 'forgot',
  LEGACY = 'legacy',
  SUCCESS = 'success',
}

export function useLoginForm(options?: { noCaptcha?: boolean }) {
  const site = useSiteIdent();
  const errors = ref<ErrorIdent[]>([]);
  const session = useSessionStore();
  const view = ref<Views>(Views.LOGIN);
  const legacyHash = ref('');
  const isLoading = ref(false);
  const { captcha } =
    options?.noCaptcha !== true ? useRecaptcha() : { captcha: null };
  const usesCaptcha = computed(
    () => !!captcha.value && options?.noCaptcha !== true,
  );
  interface loginForm {
    email: string;
    password: string;
  }

  async function onSubmit(data: loginForm) {
    //needed because when the form is subitted while the input is focused, formkit throws an error when it tries to blur the already unmounted input
    (document.activeElement as HTMLElement)?.blur();
    isLoading.value = true;
    await login(data);
    isLoading.value = false;
  }

  async function login(formData: loginForm) {
    try {
      isLoading.value = true;
      const postData = formData;
      const result = await useSecureSessionPost<Result>(
        `/api/${site}/user/login`,
        {
          ...postData,
          captcha: await (captcha.value
            ? captcha.value?.execute('submit')
            : null),
        },
      );
      if (result?.sessionId) {
        session.login(result.sessionId);
        view.value = Views.SUCCESS;
      } else if (result.legacyToken) {
        legacyHash.value = result.legacyToken;
        view.value = Views.LEGACY;
      }

      isLoading.value = false;
    } catch (e: any) {
      errors.value = e.data?.data?.errors ?? [ErrorIdent.UNKNOWN_ERROR];
      document.querySelector('.js-error-container')?.scrollIntoView({
        behavior: 'smooth',
      });
      isLoading.value = false;
      handleLoadingError(e);
    }
  }

  async function logout() {
    try {
      isLoading.value = true;
      await session.logout();
      isLoading.value = false;
    } catch (e: any) {
      errors.value = e.data.data.errors;
      isLoading.value = false;
    }
  }
  return {
    onSubmit,
    errors,
    isLoading,
    logout,
    view,
    legacyHash,
    usesCaptcha,
  };
}
